const getImageParamsByKey = (
  configs: any,
  key: 'height' | 'width',
  defaultValue: string | number = '',
) => {
  const configImageParameterValueByKey = configs?.['image_' + key]

  return Boolean(configImageParameterValueByKey)
    ? String(configImageParameterValueByKey)
    : defaultValue
}

export default getImageParamsByKey
